import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Image = styled.img`
  align-self: center;
  justify-self: flex-start;
  max-height: 150px;
`
const Heading = styled.h3`
  margin-top: 2rem;
`
const Layout = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 2rem;
  padding: 0 1rem;
`
const Grid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  #foerderer-0 {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    #foerderer-0 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`
const GridSmall = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(7, 1fr);
  
  img {
    max-width: 120px;
  }
  #foerderer-0 {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    #foerderer-0 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`
export default function Sponsors({ lang }) {
  const {
    prismic: {
      allSponsorss: { edges },
    },
  } = useStaticQuery(graphql`
    query SpnsorsQuery {
      prismic {
        allSponsorss(id: "X2SOWxIAAEt7gLL9") {
          edges {
            node {
              foerderer {
                logo
              }
              medienpartner {
                logo
              }
              kooperationen {
                logo
              }
              sponsors {
                logo
              }
              stipendiaten {
                logos
              }
              _meta {
                id
                lang
              }
            }
          }
        }
      }
    }
  `)
  const {
    foerderer,
    sponsors,
    kooperationen,
    medienpartner,
    stipendiaten,
  } = edges[0].node
  return (
    <Layout>
      <div>
        <Heading>{lang === "de-at" ? "Förderer" : "Sponsors"}</Heading>
        <Grid>
          {foerderer &&
            foerderer.map(({ logo }, i) => (
              <Image src={logo.url} id={`foerderer-${i}`} />
            ))}
        </Grid>
      </div>
      <div>
        <Heading>{lang === "de-at" ? "Sponsoren" : "Supporters"}</Heading>
        <GridSmall>
          {sponsors && sponsors.map(({ logo }) => <Image src={logo.url} />)}
        </GridSmall>
      </div>
      <div>
        <Heading>
          {lang === "de-at"
            ? "Sponsoren des Stipendiatinnen Programms"
            : "Sponsors of the Scholarship Program"}
        </Heading>
        <GridSmall>
          {stipendiaten &&
            stipendiaten.map(({ logos }) => <Image src={logos.url} />)}
        </GridSmall>
      </div>
      <div>
        <Heading>{lang === "de-at" ? "Kooperationen" : "Cooperations"}</Heading>
        <GridSmall>
          {kooperationen &&
            kooperationen.map(({ logo }) => <Image src={logo.url} />)}
        </GridSmall>
      </div>
      <div>
        <Heading>
          {lang === "de-at" ? "Medienpartner" : "Media Partners"}
        </Heading>
        <GridSmall>
          {medienpartner &&
            medienpartner.map(({ logo }) => <Image src={logo.url} />)}
        </GridSmall>
      </div>
    </Layout>
  )
}
