/* eslint-disable no-unused-vars */
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Sponsors from "../components/sponsors"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import image from "../../static/macht.png"
const Heading = styled.h1`
  padding: 0 1rem;
  max-width: 1100px;
`
const TicketLink = styled.a`
  color: white !important;
  box-sizing: border-box;
  margin-top: 1rem;
  display: inline-block;
  text-decoration: none;
  background: #6594d4;
  padding: 1rem;
`
const Paragraph = styled.p`
  padding: 0 1rem;
`
const A = styled.a`
  color: #6594d4;
`
const ParagraphSmall = styled.p`
  padding: 0 1rem;
  a {
    color: #6594d4;
  }
  p {
    min-height: 0.1rem;
    display: block;
  }
`
const Container = styled.div`
  margin-bottom: 1rem;
`
const Header = styled.img`
  height: 400px;
  width: 100vw;
  object-fit: contain;
  @media only screen and (max-width: 800px) {
    height: 200px;
  }
`
const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`
class Stipendium extends React.Component {
  render() {
    const data = this.props.data.tickets.allTicketss.edges[0].node

    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Tickets" />
        <Container>
          <Columns>
            <Paragraph>
              <br />
              <strong>Tickets for the GLOBART Academy 2021</strong>
              <br />
              <br />
              Online: <br />
              <TicketLink href="https://tickets.globart-academy.at">
                To the Ticketshop →
              </TicketLink>
              <br />
              <br />
              or via Phone or E-Mail at the GLOBART Office: <br />
              <A href="mailto:heidemarie.dobner@globart.at">
                heidemarie.dobner@globart.at
              </A>
              <br />
              <A href="tel:+436764281728">+43 676 4281728</A>
              <br />
              <br />
              <strong>NO TICKETS AT THE VENUE!!!</strong>
              <br />
              <br />
              <p>Separately available:</p>
              <strong>Tickets for the exhibition by Tino Sehgal</strong>
              <p>
                for adults  € 4.50 p.P. <br />
                for students € 3.50 p.P. <br />
                for children (6-16 years) € 1.00 p.P.{" "}
              </p>
              <p>
                Tickets are available at the entrance of the abbey park, in the
                shop in the northern bastion, and at the ticket office in the
                abbey’s first courtyard on the left side.
              </p>
            </Paragraph>
            <ParagraphSmall>{RichText.render(data.description)}</ParagraphSmall>
          </Columns>
          <Sponsors lang="en-gb" />
        </Container>
      </Layout>
    )
  }
}
export default Stipendium
export const pageQuery = graphql`
  query TicketsQueryEn {
    tickets: prismic {
      allTicketss(lang: "en-gb") {
        edges {
          node {
            description
            title
            _meta {
              id
            }
          }
        }
      }
    }
  }
`
